import * as React from "react";
import {
  PlasmicAdminStao,
  DefaultAdminStaoProps
} from "./plasmic/imbas_23_fpre/PlasmicAdminStao";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { FileUploader } from "react-drag-drop-files";
import { useEffect, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { loadStaoTemplates, TemplateInfo, uploadStaoTemplate } from "../api/endpoints/admin/stao";
import { isErrorResponse } from "../api/apiClient";
import TemplateRow from "./TemplateRow";

export interface AdminStaoProps extends DefaultAdminStaoProps {}

enum UploadStatus {
  NOTHING = "",
  UPLOADING = "UPLOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

function AdminStao_(props: AdminStaoProps, ref: HTMLElementRefOf<"div">) {

  const [ uploadStatus, setUploadStatus ] = useState<UploadStatus>(UploadStatus.NOTHING)
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);
  
  const handleStaoTemplateChange = async(fileList: FileList) => {  
    setUploadStatus(UploadStatus.UPLOADING)
    const formData = new FormData();
    formData.append("file", fileList[0]);
    cancelTokenRef.current = axios.CancelToken.source();
    await uploadStaoTemplate(formData, cancelTokenRef.current.token)
    .then((val) => {
      setUploadStatus(UploadStatus.SUCCESS)
      loadTemplateList();
    })
    .catch((err: unknown) => {
      if (!axios.isCancel(err) && isErrorResponse(err)) {
        setUploadStatus(UploadStatus.ERROR)
        alert(err);
      } else if (!axios.isCancel(err)) {
        setUploadStatus(UploadStatus.ERROR)
        alert(err);
      }
    })
  };

  const [ templates,setTemplates ] = useState<TemplateInfo[]>([])
  
  const loadTemplateList = async () => {  
    await loadStaoTemplates().then((templates) => setTemplates(templates.data));
  };

  useEffect(() => {
    loadTemplateList();
  }, [setTemplates]);
  
  return (
    <PlasmicAdminStao 
      stao={{ ref }} 
      {...props} 
      staoTemplateUpload={
          <FileUploader
            multiple={true}
            handleChange={handleStaoTemplateChange}
            name="file"
            types={["XLSX"]}
          />
      }
      uploadState={uploadStatus}
      uploadedTemplates={{
        children: templates.map((item, index) => 
          {
            const currentKey = index;    
            return (   
              <TemplateRow 
                key={currentKey}
                accountId={item.AccountId?.toString() ?? "-"}
                accountName={item.AccountName ?? (item.AccountId === 0 ? "(default)" : "-")}
                fileName={item.FileName ?? ""}
                lastModified={item.LastModified ?? ""}
              />
            )
          })
      }}
    />
  );
}

const AdminStao = React.forwardRef(AdminStao_);
export default AdminStao;
