import { CancelToken } from "axios";
import apiClient from "../../apiClient";
import { components } from "../../types";

export type TemplateInfo = components["schemas"]["TemplateInfo"];

const BASE_PATH_STAO = "/admin/stao";
export const uploadStaoTemplate = async(
  body: FormData,
  cancelToken?: CancelToken
) => {
  return await apiClient.post(`${BASE_PATH_STAO}/template`, body, {
    cancelToken,
    headers: {
      "Content-Type": "multipart/form-data"
    },
  });
};
export const loadStaoTemplates = async() => {
  return await apiClient.get<TemplateInfo[]>(`${BASE_PATH_STAO}/templates`);
};