import { Language } from "../../context/RegionContext";
import apiClient from "../apiClient";
import { components } from "../types";

export type ComparablesConfig = components["schemas"]["ComparablesConfig"];
export type ComparablesData = components["schemas"]["ComparablesData"];
export type ComparableType = components["schemas"]["ComparableType"];

const BASE_PATH = "/Comparables";

export const getComparablesConfig = (objectId: string) => {
  return apiClient.get<ComparablesConfig>(
    `${BASE_PATH}/${objectId}/config`, { params: { }, skipErrorRedirection: true });
};

export const getComparables = (objectId: string, sprache: Language, comparableType: ComparableType) => {
  return apiClient.get<ComparablesData>(
    `${BASE_PATH}/${objectId}`, { params: { sprache, comparableType }, skipErrorRedirection: true });
};

export const getComparablesDebugOutput = (objectId: string, sprache: Language, comparableType: ComparableType) => {
  return apiClient.get<Blob>(`${BASE_PATH}/${objectId}/DebugOutput`, { 
    params: { sprache, comparableType } ,
    responseType: "blob",
    headers: {
      "Accept": "application/octet-stream",
      "x-debug-output":  "true"
    }  
  });
};

export const getComparablesReport = async(objectId: string, 
                                     sprache: Language, 
                                     comparableType: ComparableType, 
                                     isDebug = false) => 
{
  return await apiClient.get<Blob>(`${BASE_PATH}/${objectId}/Report`, { 
    params: { sprache, comparableType } ,
    responseType: "blob",
    headers: {
      "Accept": "application/octet-stream",
      "x-debug-output":  `${isDebug}`
    }  
  });
};

