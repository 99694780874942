import React from "react";
import { DefaultNavigationProps, PlasmicNavigation, PlasmicNavigation__OverridesType, }
  from "./plasmic/imbas_23_fpre/PlasmicNavigation";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { ButtonProps } from "./Button";
import { useIdTokenClaims } from "../auth/authUtils";
import { FPREAllowedRole } from "../auth/jwt-claims";

export interface NavigationProps extends DefaultNavigationProps {
}


export const isAdmin = (allowedRoles: FPREAllowedRole[]): boolean => allowedRoles.some(
  ({ RoleName, Land }) => 
     RoleName === "AdminPlatform" ||
     RoleName === "AdminLabels" ||
     RoleName === "AdminStao" ||
     RoleName === "AdminComparables" ||
     RoleName === "AdminReport" 
);

function Navigation_(
  props: NavigationProps,
  ref: HTMLElementRefOf<"div">
) {
  const navigate = useNavigate();
  const location = useLocation();
  const routes = {
    root: ApplicationRoutes.getPath("root"),
    archive: ApplicationRoutes.getPath("imbasLegacy", { imbasPath: "objekte" }),
    training: ApplicationRoutes.getPath("imbasLegacy", { imbasPath: "methodenberichte" }),
    admin: ApplicationRoutes.getPath("admin"),
    apiDocs: ApplicationRoutes.getPath("apidocs"),
  };

  const allowedRoles = useIdTokenClaims()?.fpre_allowedRoles?.RoleInfo || [];
  const hasApiDocs = allowedRoles.some(
    ({ RoleName, Land }) => RoleName === "FpreApiDocs"
  );
  const specificComponentAdjustments: PlasmicNavigation__OverridesType = {
    navigationButtonAdmin: {
      render: (props, Component) => (
        isAdmin(allowedRoles) ?
          <Component
            {...props}
            onClick={() => navigate(routes.admin)}
            color={matchPath(routes.admin, location.pathname) ? "grey" : "clear"}
          />
          : null
      )
    },
    navigationButtonApiDocs: {
      render: (props, Component) => (
        hasApiDocs ?
          <Component
            {...props}
            onClick={() => navigate(routes.apiDocs)}
            color={matchPath(routes.apiDocs, location.pathname) ? "grey" : "clear"}
          />
          : null
      )
    }
  }

  const getNavigationButtonProps = (path: string): ButtonProps => ({
    onClick: () => navigate(path),
    color: matchPath(path, location.pathname) ? "grey" : "clear",
  });

  return (
    <PlasmicNavigation
      root={{ ref }}
      {...specificComponentAdjustments}
      {...props}
      navigationButtonModule={getNavigationButtonProps(routes.root)}
      navigationButtonArchive={getNavigationButtonProps(routes.archive)}
      navigationButtonTraining={getNavigationButtonProps(routes.training)}
    />
  );
}

const Navigation = React.forwardRef(Navigation_);
export default Navigation;
