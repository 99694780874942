import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react"
import { useTranslation } from "react-i18next";
import { LocationDetail } from "../api/endpoints/location";
import { useIdTokenClaims } from "../auth/authUtils";


export enum Country {
  CH = 1,
  DE = 2,
  LI = 3,
  LU = 4
}

export enum Language {
  IT = 1,
  FR = 2,
  DE = 3,
  EN = 4,
  LB = 5
}

export const allowedLanguages = ([
  { key: Country.CH, value: [ Language.DE, Language.EN, Language.FR, Language.IT ]},
  { key: Country.LI, value: [ Language.DE, Language.EN ]},
  { key: Country.DE, value: [ Language.DE, Language.EN ]},
  { key: Country.LU, value: [ Language.DE, Language.FR ]}
]);

const RegionContext = createContext<{
  language: Language,
  country: Country,
  location: LocationDetail | undefined,
  setLanguage: Dispatch<SetStateAction<Language>>,
  setCountry: Dispatch<SetStateAction<Country>>,
  setLocation: Dispatch<SetStateAction<LocationDetail | undefined>>,
}>({
  language: Language.DE,
  country: Country.CH,
  location: undefined,
  setLanguage: () => undefined,
  setCountry: () => undefined,
  setLocation: () => undefined,
});

export const useRegionContext = () => useContext(RegionContext);

export const LOCAL_STORAGE_LOCATION_KEY = "location";

export const deriveIsoLocale = (country: Country, language: Language): string => {
  if (language === Language.EN) {
    return "en-US";
  }
  return `${Language[language].toLowerCase()}-${Country[country].toUpperCase()}`;
};


const getInitialLocation = (): LocationDetail | undefined => {
  const localStorageLocation = localStorage.getItem(LOCAL_STORAGE_LOCATION_KEY);
  return localStorageLocation ? JSON.parse(localStorageLocation) : undefined;
};

export const RegionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();
  const fpre_userData = useIdTokenClaims()?.fpre_userData;

  
  const [language, setLanguage] = useState<Language>(Language.DE);
  const [country, setCountry] = useState<Country>(Country.CH);
  const [location, setLocation] = useState<LocationDetail | undefined>(getInitialLocation());
  
  useEffect(() => {
    if(fpre_userData) {
      const { DefaultSprache, DefaultLand } = fpre_userData ?? {};
      if(DefaultSprache && DefaultLand) {
        const defaultSprache = Language[DefaultSprache.toUpperCase() as keyof typeof Language];
        if(defaultSprache !== language)
          setLanguage(defaultSprache);
        const defaultLand = Country[DefaultLand.toUpperCase() as keyof typeof Country];
        if(location === undefined) {
          setCountry(defaultLand);
        }
      }
    }
  }, [fpre_userData?.DefaultSprache, fpre_userData?.DefaultLand]);

  useEffect(() => {
    if (location) {
      localStorage.setItem(LOCAL_STORAGE_LOCATION_KEY, JSON.stringify(location));
      setCountry(location.LAND);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_LOCATION_KEY);
    }
  }, [location]);

  useEffect(() => {
    const langInCountry = allowedLanguages.find((element) => element.key === country)?.value;
    if(langInCountry && !langInCountry.includes(language))
      setLanguage(langInCountry[0]) 
    i18n.changeLanguage(deriveIsoLocale(country, language));
  }, [country, language]);

  return (
    <RegionContext.Provider value={{ language, country, location, setLanguage, setCountry, setLocation }}>
      {children}
    </RegionContext.Provider>
  );
};
