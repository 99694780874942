import * as React from "react";
import {
  PlasmicTemplateRow,
  DefaultTemplateRowProps
} from "./plasmic/imbas_23_fpre/PlasmicTemplateRow";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface TemplateRowProps extends DefaultTemplateRowProps {
  accountId: string;
  accountName: string;
  fileName: string;
  lastModified: string;
}

function TemplateRow_(
  { accountId, accountName, fileName, lastModified, ...rest }: TemplateRowProps, 
  ref: HTMLElementRefOf<"div">
) {
  

  return (
    <PlasmicTemplateRow 
      uploadedTemplatesRow={{ ref }} 
      {...rest} 
      accountId={ accountId }
      accountName={ accountName }
      fileName={ fileName }
      lastModified={ lastModified }
      />);
}

const TemplateRow = React.forwardRef(TemplateRow_);
export default TemplateRow;
