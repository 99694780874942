import * as React from "react";
import {
  PlasmicAdminPage,
  DefaultAdminPageProps
} from "./plasmic/imbas_23_fpre/PlasmicAdminPage";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useState } from "react";
import AdminLabels from "./AdminLabels";
import AdminStao from "./AdminStao";
import { useIdTokenClaims } from "../auth/authUtils";
import { isAdmin } from "./Navigation";
import ErrorPage from "./ErrorPage";

export interface AdminPageProps extends DefaultAdminPageProps {}

function AdminPage_(props: AdminPageProps, ref: HTMLElementRefOf<"div">) {
  enum AdminPages { Labels = "AdminLabels", Stao = "AdminStao"  }

  const CurrentPage = () => {
      switch(currentPage) {
        case AdminPages.Labels: return <AdminLabels />;
        case AdminPages.Stao: return <AdminStao />;
        default:      return null
      }
    }
  const allowedRoles = useIdTokenClaims()?.fpre_allowedRoles?.RoleInfo || [];
  const isAdminLabels = allowedRoles.some(
    ({ RoleName, Land }) => RoleName === AdminPages.Labels
  );
  const isAdminStao = allowedRoles.some(
    ({ RoleName, Land }) => RoleName === AdminPages.Stao
  );

  const selectInitialPage = (): AdminPages | null =>{
    if(isAdminLabels)
      return AdminPages.Labels
    else if(isAdminStao)
      return AdminPages.Stao;
    else 
      return null;
    }
  const [currentPage, setCurrentPage] = useState<AdminPages | null>(selectInitialPage);

  return (
    isAdmin(allowedRoles)?
      <PlasmicAdminPage 
        root={{ ref }} 
        {...props }
        labels= {{
          render: (props, Component) => (
            isAdminLabels ?
              <Component
                {...props}
                onClick={() => setCurrentPage(AdminPages.Labels) }
                color={currentPage === AdminPages.Labels ? "blue" : "clear"}
              />
              : null
          )
        }}
        stao={{
          render: (props, Component) => (
            isAdminStao ?
              <Component
                {...props}
                onClick={() => setCurrentPage(AdminPages.Stao) }
                color={currentPage === AdminPages.Stao  ? "blue" : "clear"}
              />
              : null
          )
        }}
        adminContent= {CurrentPage()}
      /> 
      : <ErrorPage key={401}/>
  );
}

const AdminPage = React.forwardRef(AdminPage_);
export default AdminPage;

