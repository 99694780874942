import * as React from "react";
import { useContext, useEffect } from "react";
import { DefaultSliderProps, PlasmicSlider } from "./plasmic/imbas_23_fpre/PlasmicSlider";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { usePrevious } from "../hooks/usePrevious"
import { useDataStore } from "../store/DataStoreProvider";
import { get } from "lodash";
import { ApplyAlgoValuesContext } from "../hooks/useApplyAlgoValues";

export interface SliderProps extends DefaultSliderProps {
  value?: number;
  onChange: (value: number | null) => void;
}

function Slider_(props: SliderProps, ref: HTMLElementRefOf<"div">) {
  const { value, onChange, ...rest } = props;
  const { algoMappingRef } = useContext(ApplyAlgoValuesContext);
  const { dataStore } = useDataStore();
  const preservedValue = usePrevious<number | undefined>(value);

  useEffect(() => {
    if (!algoMappingRef || !props.name || !props.algoName) return;

    algoMappingRef.current.push({ name: props.name, algoName: props.algoName });
    return () => {
      algoMappingRef.current = algoMappingRef.current.filter(({ name }) => name !== props.name);
    }
  }, [props.name, props.algoName, algoMappingRef]);

  const handleSliderInputChange = (v: string | null) => {
    onChange(v ? parseFloat(v) : null)
  };

  const handleEmptySliderInput = () => {
    if (!value) {
      onChange(preservedValue as number)
    }
  }

  const getValueFromDataStore = () => {
    if (!props.algoName) return;
    return get(dataStore, props.algoName) ?? undefined;
  }

  return <PlasmicSlider
    root={{ ref }}
    sliderBase={{ value, onChange }}
    sliderInput={{
      value: value === null? "" :String(value),
      onChange: handleSliderInputChange,
      onBlur: handleEmptySliderInput
    }}    
    clearButton={{
      onClick: () => handleSliderInputChange(null) 
    }}
    {...rest}
    algoValue={getValueFromDataStore()}
  />;
}

const Slider = React.forwardRef(Slider_);
export default Slider;
