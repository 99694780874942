import * as React from "react";
import {
  PlasmicDebugFlag,
  DefaultDebugFlagProps
} from "./plasmic/imbas_23_fpre/PlasmicDebugFlag";
import { useDebugContext } from "../context/DebugContext";

export interface DebugFlagProps extends DefaultDebugFlagProps {}

function DebugFlag(props: DebugFlagProps) {
  const { isDebugMode, setIsDebugMode } = useDebugContext();

  return (
    <PlasmicDebugFlag
    {...props}  
    debugCheckBox={{
      onChange: () => {
        setIsDebugMode(!isDebugMode);
      },
      value: isDebugMode
    }}      
  />);
}

export default DebugFlag;
