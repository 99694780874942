import React, { RefObject, useEffect, useState } from "react";
import Navigation from "../Navigation";
import { useIdTokenClaims } from "../../auth/authUtils";
import ErrorPage from "../ErrorPage";
type Props = {
    title?: string;
    iframeRef?: RefObject<HTMLIFrameElement>;
};
const ApiDocsIframe: React.FC<Props> = ({ title, iframeRef }) => {
    const [src, setSrc] = useState<string>("about:blank");
    useEffect(() => {
        const iframeUrl = new URL(process.env.REACT_APP_APIDOCS_URL ?? "");
        setSrc(iframeUrl.toString());
    });

    
    const allowedRoles = useIdTokenClaims()?.fpre_allowedRoles?.RoleInfo || [];
    const hasApiDocs = allowedRoles.some(
        ({ RoleName, Land }) => RoleName === "FpreApiDocs"
    );

    return (
        hasApiDocs ?
        <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <Navigation />
            <iframe
                title={title || "FPRE API Docs"}
                ref={iframeRef}
                src={src}
                style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    opacity: 1,
                }}
            />
        </div>
        : <ErrorPage key={401}/>
    );
};
export default ApiDocsIframe;