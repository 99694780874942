import { components } from "../types";
import apiClient from "../apiClient";

export type GemcInput = {
  wgs84Lng: number | null | undefined;
  wgs84Lat: number | null | undefined;
  sprache: components["schemas"]["SPRACHE"];
  land: components["schemas"]["LAND"];
  fileType: components["schemas"]["FileType"];
  gemcType: components["schemas"]["GemcType"];
};

export type StaoInput = {
  wgs84Lng: number | null | undefined;
  wgs84Lat: number | null | undefined;
  sprache: components["schemas"]["SPRACHE"];
  land: components["schemas"]["LAND"];
  fileType: components["schemas"]["FileType"];
  staoProductCode: components["schemas"]["StaoProductCode"];
  street: string | null | undefined;
  number: string | null | undefined;
  zipCode: string | null | undefined;
  city: string | null | undefined;
};
const BASE_PATH = "/Download";

export const getGemc = async(query: GemcInput) => {
  return await apiClient.get<Blob>(`${BASE_PATH}/gemc`, {
    params: { ...query },
    responseType: "blob"
  });
};

export const getStao = async(query: StaoInput, isDebug = false) => {
  return await apiClient.get<Blob>(`${BASE_PATH}/stao`, {
    params: { ...query },
    responseType: "blob",
    headers: {
      "x-debug-output":  `${isDebug}`
    }  
  });
};
